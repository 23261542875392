import PropTypes from "prop-types";
import React from "react";
import Section from "../../components/Section/Section";

/**
 * Returns the background styles for the section. Options include a backgorund
 * image and gradient.
 */
const getBackgroundStyle = ({
  backgroundGradientLight: hexLight,
  backgroundGradientDark: hexDark,
  backgroundImage: bgImg,
  backgroundImagePosition: bgPos,
  backgroundSize: bgSize
}) => {

  const url = (bgImg?.src)
    ? bgImg.src
    : (bgImg?.useImage)
      ? bgImg.useImage().imgData.src
      : bgImg?.localFile.publicURL;

  const style = {
    backgroundImage:
      `url("${url}"),
      linear-gradient(135deg, ${hexLight} 0%, ${hexDark} 100%)`,
    backgroundSize: bgSize
  };

  if (bgImg && bgPos) {
    style.backgroundPosition = bgPos;
  }

  return style;
};


const HeroSection = ({ children, className, id, ...others }) => {
  return (
    <Section className={className} id={id} style={getBackgroundStyle(others)} >
      {children}
    </Section>
  );
};

HeroSection.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  backgroundGradientLight: PropTypes.string,
  backgroundGradientDark: PropTypes.string,
  backgroundImage: PropTypes.object,
  backgroundImagePosition: PropTypes.string,
  backgroundSize: PropTypes.string
};

export default HeroSection;
