import React, { useEffect, useState } from 'react';
import Button from '../../components/Button/Button';
import DownArrow from '../../assets/svgs/down-arrow.svg';
import DownArrowRed from '../../assets/svgs/down-arrow-red.svg';
import HeroSection from '../../components/HeroSection/HeroSection';
import { InView } from 'react-intersection-observer';
import FluidImage from '../../components/FluidImage/FluidImage';
import { addRedPeriod } from 'utils/helpers';
import fadeInSlide from 'utils/animations/fade-in-slide';

import './CtaScroll.scss';


const CtaScroll = ({ sections = [] }) => {
  const [isTarget, setTarget] = useState('');
  const totalSections = sections.length;

  const checkView = (inView, entry) => {
    if (entry.isIntersecting) {
      setTarget(entry.target.childNodes[0].id);
    }
  };

  useEffect(() => {
    const links = document.querySelectorAll('a.Hash--selector');

    for (const link of links) {
      link.addEventListener('click', clickHandler);
    }

    function clickHandler(e) {
      e.preventDefault();
      const href = this.getAttribute('href');
      const offsetTop = document.querySelector(href).offsetTop;

      scroll({
        top: offsetTop,
        behavior: 'smooth',
      });
    }
  });

  return (
    <>
      {sections.map((section, i) => {
        const ActiveStyle = section.ctaVariant === 'dark' ? 'Active--style' : '';
        const newUrl = new URL(section.cta.url);
        const [ leftTweenRef ] = fadeInSlide('x', -25);
        const [ rightTweenRef ] = fadeInSlide('x', 25);
        // const sectionTitle = (i === 0) ? addRedPeriod(section.title) : section.title;
        const sectionTitle = section.title;
        const imgRight = (section.rightImage?.useImage)
          ? section.rightImage.useImage().imgData
          : section.rightImage;


        return (
          <InView
            threshold={[0.75]}
            key={i}
            onChange={(inView, entry) => checkView(inView, entry)}
          >
            <HeroSection
              className="CtaScroll"
              id={`${section.sectionId}`}
              {...section}
            >
              <div className="container">
                <div className="row align-items-sm-start align-items-md-center">
                  <div className="col-lg-6">
                    <div ref={leftTweenRef} className="alpha0">
                      <div className="SubTitle" style={{ color: section.tagColor }}>
                        {section.tag}
                      </div>
                      <h3
                        className="Text--light"
                        style={{ color: section.titleColor }}
                        dangerouslySetInnerHTML={{ __html: sectionTitle }}
                      />
                      <div className="pb-4 pt-3 Text--light">
                        <div
                          style={{ color: section.descriptionColor }}
                          dangerouslySetInnerHTML={{ __html: section.description }}
                          className="Description"
                        />
                      </div>
                    </div>
                    {!section.cta?.url ? null : (
                      <Button
                        to={newUrl.pathname}
                        variant={section.ctaVariant}
                        target={section.cta.target}
                      >
                        {section.cta.title}
                      </Button>
                    )}
                  </div>
                  <div className="col-lg-6">
                    <div ref={rightTweenRef} className="alpha0">
                      <FluidImage className="SectionRightImage" image={imgRight} />
                    </div>
                  </div>
                </div>

                {totalSections === i + 1 ? null : (
                  <a href={`#${sections[i+1].sectionId}`} className={`GoNext Hash--selector ${ActiveStyle}`}>
                    <img
                      src={ActiveStyle === '' ? DownArrow : DownArrowRed}
                      alt="Next"
                      style={{}}
                    />
                  </a>
                )}
              </div>
            </HeroSection>
          </InView>
        );
      })}

      <nav className="Indicators">
        <ul>
          {!sections
            ? null
            : sections.map((bullet, i) => {
                const activeClass = isTarget === `${bullet.sectionId}` ? 'Active' : '';
                const activeBrand = bullet.ctaVariant === 'dark' ? 'Active--brand' : '';
                return (
                  <li key={i}>
                    <a
                      href={`#${bullet.sectionId}`}
                      className={`${activeClass} ${activeBrand} Hash--selector`}
                    >
                      {bullet.title}
                    </a>
                  </li>
                );
              })}
        </ul>
      </nav>
    </>
  );
};

export default CtaScroll;
